import React from 'react';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import glimmerStyles from '../../../stylesheets/components/glimmer.module.scss';
import classNames from 'classnames';

interface PageHeaderProps {
  title: string;
  children?: React.ReactNode;
  titleElements?: React.ReactNode;
  dataTestClass?: string;
  includeBottomMargin?: boolean;
}

const PageHeader = ({
  title,
  children,
  titleElements,
  dataTestClass,
  includeBottomMargin = true,
}: PageHeaderProps) => {
  return (
    <div
      className={classNames(
        layoutStyles.flex,
        layoutStyles.alignCenter,
        layoutStyles.spaceBetween,
        {
          [layoutStyles.mb20]: includeBottomMargin,
        }
      )}
      id="page-header"
      data-test-class={dataTestClass}
    >
      <h1
        className={classNames(
          layoutStyles.flex,
          layoutStyles.alignCenter
          /*layoutStyles.noWrap*/
        )}
      >
        {title}
        {titleElements}
      </h1>
      {children}
    </div>
  );
};

export const PageHeaderGlimmer = () => {
  return (
    <div
      className={classNames(
        layoutStyles.flex,
        layoutStyles.alignCenter,
        layoutStyles.spaceBetween,
        layoutStyles.mb20
      )}
    >
      <h1 className={glimmerStyles.glimmerElement}></h1>
    </div>
  );
};

export default PageHeader;
